import * as React from "react";
import {
    SelectInput, TextInput, required, useTranslate, useGetList, Loading, Error, number, minValue
} from 'react-admin';

const BinRangeStep = props => {
    const translate = useTranslate();
    const inputStyle = { width: '100%' };

    const { data: issuerData, isLoading: isIssuerLoading, error: issuerError } = useGetList(
        'ip_issuer_management',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                order: 'ASC'
            }
        }
    );

    if (isIssuerLoading) return <Loading />;
    if (issuerError) return <Error />;
    if (!issuerData) return null;

    const validateEndGreaterThanStart = (value, allValues) => {
        if (parseInt(value, 10) <= parseInt(allValues.binRange.start, 10)) {
            return translate('kuggar.bin_ranges.end_greater_than_start_validation');
        }
        return undefined;
    };

    const validateBinRangeStart = [required(), number('Must be numeric value')];
    const validateBinRangeEnd = [required(), number('Must be numeric value'), validateEndGreaterThanStart]
    const validateBinLength = [required(), number('Must be numeric value'), minValue(1, translate('kuggar.bin_ranges.bin_length_greater_than_zero_validation'))];

    return (
        <>
            <TextInput source="binRange.description"
                validate={[required()]}
                label={translate('kuggar.shared.description')}
                fullWidth={true} />
            <TextInput source="binRange.start"
                validate={validateBinRangeStart}
                label={translate('kuggar.bin_ranges.start')}
                fullWidth={true} />
            <TextInput source="binRange.end"
                validate={validateBinRangeEnd}
                label={translate('kuggar.bin_ranges.end')}
                fullWidth={true} />
            <TextInput source="binRange.binLength"
                validate={validateBinLength}
                label={translate('kuggar.bin_ranges.bin_length')}
                fullWidth={true} />
            <SelectInput
                source="binRange.issuerId"
                label={translate('kuggar.ip_issuer_management.issuer')}
                validate={[required()]}
                choices={issuerData}
                optionText="name"
                optionValue="id"
                style={inputStyle} />
        </>
    );
};

export default BinRangeStep;