import * as React from "react";
import {
    SelectInput, TextInput, required, useTranslate, NumberInput, DateInput, number, minValue,
} from 'react-admin';

const FundingAccountCreditDetails = () => {
    const translate = useTranslate();

    const validateCreditAccountNumber = [required(), number('Must be numeric value')];
    const validateCreditAccountBin = [required(), number('Must be numeric value')];

    return(
        <>
            <TextInput source="fundingAccount.creditAccountDetails.accountNumber"
                validate={validateCreditAccountNumber}
                label={translate('kuggar.funding_accounts.credit_account_number')}
                fullWidth={true} />
            <TextInput source="fundingAccount.creditAccountDetails.bin"
                validate={validateCreditAccountBin}
                label={translate('kuggar.funding_accounts.credit_account_bin')}
                fullWidth={true} />
            <DateInput source="fundingAccount.creditAccountDetails.expiryDate"
                label={translate('kuggar.funding_accounts.credit_account_expiry_date')}
                fullWidth={true}
                validate={[required()]} />            
        </>
    );
};

const FundingAccountStep = props => {
    const translate = useTranslate();

    const validateAuthLimit = [required(), number('Must be numeric value'), minValue(1, 'Must be positive number')];

    return (
        <>
            <TextInput source="fundingAccount.type"
                style={{ display: 'none' }}
                defaultValue="Credit" />
            <TextInput source="fundingAccount.description"
                validate={[required()]}
                label={translate('kuggar.shared.description')}
                fullWidth={true} />
            <NumberInput source="fundingAccount.authorisationLimit"
                validate={validateAuthLimit}
                label={translate('kuggar.funding_accounts.auth_limit')}
                fullWidth={true} />
            <SelectInput source="fundingAccount.currencyCode"
                validate={[required()]}
                label={translate('kuggar.shared.currency_code')}
                fullWidth={true}
                choices={[
                    { id: 'USD', name: 'USD' },
                    { id: 'AUD', name: 'AUD' }
                ]}
            />

            <FundingAccountCreditDetails/>
        </>
    );
};

export default FundingAccountStep;