import * as React from "react";
import { 
    List, Datagrid, TextField, CreateButton,
    Create, useNotify, useRedirect, useTranslate
} from 'react-admin';
import { WizardForm, WizardFormStep } from "@react-admin/ra-form-layout";
import { Box, Typography } from '@mui/material';
import SchemeStep from '../../components/ip_onboarding/SchemeStep';
import FundingAccountStep from '../../components/ip_onboarding/FundingAccountStep';
import BinRangeStep from '../../components/ip_onboarding/BinRangeStep';
import CardProcessorPolicyStep from '../../components/ip_onboarding/CardProcessorPolicyStep';
import CardIssuerTemplateStep from '../../components/ip_onboarding/CardIssuerTemplateStep';

export const IssuerOnboardingList = () => {
    const translate = useTranslate();

    const Empty = () => (
        <Box sx={{ width:'100%', marginTop:'20px', textAlign:'center' }}>
            <Typography variant="h6" color="inherit">{translate('kuggar.ip_onboarding.list_title')}</Typography>
            <Typography variant="body2">{translate('kuggar.ip_onboarding.list_subtitle')}</Typography>
            <br/>
            <CreateButton label={translate('kuggar.ip_onboarding.create_title')} />
        </Box>
    );

    return (
        <List empty={<Empty />}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')} />
            </Datagrid>
        </List>
    );
};

export const IssuerOnboardingCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const translate = useTranslate();

    const onSuccess = () => {
		notify(`${translate('kuggar.organisation.on_success_notify')}`);
		redirect(`/ip_card_issuer_template`);
    };

    return (
        <Create title={translate('kuggar.ip_onboarding.create_title')} mutationOptions={{ onSuccess }}>
            <WizardForm>
                <WizardFormStep label="Scheme">
                    <SchemeStep/>
                </WizardFormStep>                
                <WizardFormStep label="Funding Account">
                    <FundingAccountStep/>
                </WizardFormStep>
                <WizardFormStep label="BIN Range">
                    <BinRangeStep/>
                </WizardFormStep>
                <WizardFormStep label="Card Processor Policy">
                    <CardProcessorPolicyStep/>
                </WizardFormStep>
                <WizardFormStep label="Card Issuer Template">
                    <CardIssuerTemplateStep/>
                </WizardFormStep>
            </WizardForm>
        </Create>
    );
};